.ce-paragraph {
  line-height: 1.6em;
  outline: none;
}

/**
* Normally paragraph placeholder is shown only for the focused block (thanks to "data-placeholder-active").
*
* But there is a special case when the paragraph is the only block in the empty editor.
* When editor autofocus=false, there will be no focus. We need to show the placeholder anyway.
*/
.ce-block:only-of-type .ce-paragraph[data-placeholder-active]:empty::before,
.ce-block:only-of-type .ce-paragraph[data-placeholder-active][data-empty="true"]::before {
content: attr(data-placeholder-active);
}

.ce-paragraph p:first-of-type{
  margin-top: 0;
}

.ce-paragraph p:last-of-type{
  margin-bottom: 0;
}